
import { Options, Vue } from "vue-class-component";
import { authModule } from "@/store";
import { Auth } from "@/types/enum";

@Options({
  name: "AppBar",
  components: {},
})
export default class AppBar extends Vue {
  private open = false;
  private iconSize = 24;
  private menuItems: Array<string> = ["Profile", "-", "Log out"];

  private onSelected(data: {[key: string]: any}): void {
    const menuItemIndex: number = data.index;

    if (menuItemIndex === Auth.Logout) {
      authModule.Logout();
    }
  }
}
