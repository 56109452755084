
import { Options, Vue } from "vue-class-component";
import Navigation from "@/layout/navigation/index.vue";
import AppBar from "@/layout/appBar/index.vue";

@Options({
  name: "PageWrapper",
  components: { Navigation, AppBar },
  watch: {
    searchString(val) {
      this.emitter.emit("search", val);
    },
  },
})
export default class PageWrapper extends Vue {
  private searchString = "";

  private get getRouteData(): any {
    if (!this.$route.meta.title || !this.$route.meta.description) {
      return { title: "", description: "" };
    }

    return {
      title: this.$route.meta.title,
      description: this.$route.meta.description,
    };
  }
}
