import { Vue } from "vue-class-component";
import interact from "interactjs";

const getPositionInPercentage = (pos: number, dimension: number) => {
  return (100 * pos) / (dimension / 2);
};

export default class InteractMixin extends Vue {
  static values: {
    w: number;
    h: number;
    x: number;
    y: number;
  } = { w: 0, h: 0, x: 0, y: 0 };
  private dragMoveListener(event: any) {
    const wrapper = document
      .querySelector(".visualisation")!
      .getBoundingClientRect();
    const target = event.target;
    const x = (parseFloat(target.getAttribute("data-x")) || 0) + event.dx;
    const y = (parseFloat(target.getAttribute("data-y")) || 0) + event.dy;

    const xInPercentage = getPositionInPercentage(x, wrapper.width);
    const yInPercentage = getPositionInPercentage(y, wrapper.height);

    target.style.transform =
      "translate(" + xInPercentage + "%," + yInPercentage + "%)";

    target.setAttribute("data-x", x);
    target.setAttribute("data-y", y);
    InteractMixin.setParamsOutput({
      w: event.rect.width,
      h: event.rect.height,
      x: x,
      y: y,
    });
  }

  static setParamsOutput({
    w,
    h,
    x,
    y,
  }: {
    w: number;
    h: number;
    x: number;
    y: number;
  }): void {
    // const interactH = document.getElementById("interactH");
    // interactH!.innerHTML = "";

    if (x) {
      this.values.x = Math.round(x);
    }

    if (y) {
      this.values.y = Math.round(y);
    }

    if (w) {
      this.values.w = Math.round(w);
    }

    if (h) {
      // interactH!.innerHTML = `h: ${Math.round(h)}px`;
      this.values.h = Math.round(h);
    }
  }

  protected onInteract(selector: string) {
    interact(selector)
      .resizable({
        edges: { left: true, right: true, bottom: true, top: true },
        listeners: {
          move(event: any) {
            const wrapper = document
              .querySelector(".visualisation")!
              .getBoundingClientRect();
            const target = event.target;
            let x = parseFloat(target.getAttribute("data-x")) || 0;
            let y = parseFloat(target.getAttribute("data-y")) || 0;

            const widthInPercentage = (100 * event.rect.width) / wrapper.width;
            const heightInPercentage =
              (100 * event.rect.height) / wrapper.height;

            target.style.width = widthInPercentage + "%";
            target.style.height = heightInPercentage + "%";

            x += event.deltaRect.left;
            y += event.deltaRect.top;

            const xInPercentage = getPositionInPercentage(x, wrapper.width);
            const yInPercentage = getPositionInPercentage(y, wrapper.height);

            target.style.transform =
              "translate(" + xInPercentage + "%," + yInPercentage + "%)";

            target.setAttribute("data-x", x);
            target.setAttribute("data-y", y);
            target.setAttribute("data-w", event.rect.width);
            target.setAttribute("data-h", event.rect.height);

            InteractMixin.setParamsOutput({
              w: event.rect.width,
              h: event.rect.height,
              x: x,
              y: y,
            });
          },
        },
        modifiers: [
          interact.modifiers.restrictEdges({
            outer: "parent",
          }),

          interact.modifiers.restrictSize({
            min: { width: 100, height: 100 },
          }),
        ],

        inertia: true,
      })
      .draggable({
        inertia: true,
        modifiers: [
          interact.modifiers.restrictRect({
            restriction: "parent",
            endOnly: true,
          }),
        ],
        listeners: {
          move: this.dragMoveListener,
        },
      })
      .on("dragstart", (event: any) => {
        const wrapper = document
          .querySelector(".visualisation")!
          .getBoundingClientRect();
        const target = event.target;

        if (target.style.transform) {
          const transform = target.style.transform;
          const [x, y] = transform
            .substring(
              transform.lastIndexOf("(") + 1,
              transform.lastIndexOf(")")
            )
            .split(",");

          const pixelsX = (x * wrapper.width) / 100;
          const pixelsY = (y * wrapper.height) / 100;

          //set data x, y attribute
          if (
            !target.getAttribute("data-x") ||
            !target.getAttribute("data-y")
          ) {
            target.setAttribute("data-x", pixelsX.toString());
            target.setAttribute("data-y", pixelsY.toString());
          }
        }
      });
  }

  protected onDestroy(selector: string, isImage: boolean) {
    interact(selector).unset();
    InteractMixin.setParamsOutput({ w: 0, h: 0, x: 0, y: 0 });

    if (!isImage) {
      document.querySelector(selector)?.removeAttribute("style");
      document.querySelector(selector)?.removeAttribute("data-x");
      document.querySelector(selector)?.removeAttribute("data-y");
      document.querySelector(selector)?.removeAttribute("data-w");
      document.querySelector(selector)?.removeAttribute("data-h");
    }
  }

  protected getParams() {
    const wrapper = document
      .querySelector(".visualisation")!
      .getBoundingClientRect();
    return {
      container: {
        width: wrapper.width,
        height: wrapper.height,
      },
    };
  }
}
