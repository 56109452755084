const config = {
  base: "https://anatomy4sculptors.com/",
  lang: ["en", "lv"],
  systemName: "Human Proportion Calculator 2.0 (Beta)",
  supportEmail: "support@anatomy4sculptors.com",
  api_host: "/api",
  assets_path: "/assets",
  slider: {
    ratio: 1.4,
  },
  sortable: {
    sort: true,
    dataIdAttr: "data-row-id",
    draggable: ".mdc-data-table__row",
    animation: 100,
  },
  markers: {
    startPoint: {
      x: "5",
      y: "5"
    },
    endPoint: {
      x: "15", y: "5"
    }
  },
  assets: [
    {
      id: 0,
      name: "albums",
      title: "Albums",
      icon: "albums-icon",
      visible: true,
    },
  ],
  socials: [
    { filename: "share.svg", url: "", name: "email" },
    {
      filename: "facebook.svg",
      url: "https://www.facebook.com/anatomynext",
      name: "facebook",
    },
    {
      filename: "twitter.svg",
      url: "https://twitter.com/AnatomyNext",
      name: "twitter",
    },
    {
      filename: "linkedin.svg",
      url: "https://www.linkedin.com/company/anatomy-next/",
      name: "linkedin",
    },
    {
      filename: "whatsapp.svg",
      url: "https://www.anatomy.app",
      name: "whatsapp",
    },
    { filename: "pinterest.svg", url: "", name: "pinterest" },
  ],
};

export default config;
