import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ui_nav_item = _resolveComponent("ui-nav-item")!
  const _component_ui_nav = _resolveComponent("ui-nav")!

  return (_openBlock(), _createBlock(_component_ui_nav, null, {
    default: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.menu, (m, i) => {
        return (_openBlock(), _createBlock(_component_ui_nav_item, {
          key: i,
          active: _ctx.getActiveRoute.indexOf(m.name) > -1,
          onClick: ($event: any) => (_ctx.onPageChange(m.link)),
          href: "javascript:void(0)"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(m.title), 1)
          ]),
          _: 2
        }, 1032, ["active", "onClick"]))
      }), 128))
    ]),
    _: 1
  }))
}