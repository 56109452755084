import { Vue } from "vue-class-component";
import { copy } from "@/utils";
import useVuelidate from "@vuelidate/core";

export default class Forms extends Vue {
  protected rMessage!: string;
  protected readonly toastTimeOut = 2000;
  protected isSaved = false;
  protected isImageUploaded = true;
  protected v$ = useVuelidate();

  protected get getValidator(): any {
    return this.v$;
  }

  protected get getFormModel(): { [key: string]: string } {
    return this.getValidator.formData.$model;
  }

  protected get isEmptyValues(): boolean {
    const model = copy(this.getFormModel);
    delete model.npk;

    return Object.values(model).some((v) => !v);
  }

  protected get isValidInputs(): boolean {
    return !this.getValidator.$invalid;
  }

  protected get isDisabled(): boolean {
    return (
        this.isEmptyValues ||
        !this.isValidInputs ||
        !this.isImageUploaded ||
        this.isSaved
    );
  }

  protected get isEditSubRoute(): boolean {
    return this.$route.matched.some(
        ({ name }: { name: any }) => name.indexOf("Edit") > -1
    );
  }

  protected get isCreateSubRoute(): boolean {
    return this.$route.matched.some(
        ({ name }: { name: any }) => name.indexOf("Create") > -1
    );
  }

  protected get roastMessage(): string {
    return this.rMessage;
  }

  protected set roastMessage(message: string) {
    this.rMessage = message;
  }
}
