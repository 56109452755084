import {Vue} from "vue-class-component";
import config from "@/config/config";
import Sortable from "sortablejs";

export default class SortableMixin extends Vue {
    $refs!: {
        table: HTMLFormElement;
    };

    protected onListSort(Model: any, currentTable?: any): void {
        const tableEl = currentTable ? currentTable : this.$refs.table;
        if (!tableEl) return;

        const [tableContainer, ...rest] = tableEl.el.children;
        const [table] = tableContainer.children;
        const [, , tbody] = table.children;
        const sortableConfig = {
            ...config.sortable,
            ...{
                store: {
                    set: (sortable: any) => {
                        const ids: Array<string> = [];
                        const list: any = Array.from(tbody.children);
                        const order: Array<string> = sortable.toArray();

                        for (const o of order) {
                            const [row] = list.filter((l: any) => l.dataset.rowId === o);
                            const [, id, , ...rest] = row.children;

                            ids.push(id.innerText);
                        }

                        Model.Sort(ids);
                        ids.length = 0;
                    },
                },
            },
        };

        new Sortable(tbody, sortableConfig);
    }
}
