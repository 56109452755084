
import { Options, Vue } from "vue-class-component";

@Options({
  name: "Navigation",
  components: {},
})
export default class Navigation extends Vue {
  private menu: Array<{ [key: string]: number | string }> = [
    {
      id: 1,
      name: "dashboard",
      title: "Dashboard",
      link: "/dashboard",
    },
    {
      id: 2,
      name: "users",
      title: "Users",
      link: "/users",
    },
    {
      id: 3,
      name: "languages",
      title: "Languages",
      link: "/languages",
    },
    {
      id: 4,
      name: "menu",
      title: "Menu",
      link: "/menu",
    },
    {
      id: 5,
      name: "settings",
      title: "Settings",
      link: "/settings",
    },
  ];

  private get getActiveRoute(): string {
    if (!this.$route.name) return "";

    return this.$route.name.toString().toLowerCase();
  }

  private onPageChange(link: string): void {
    this.$router.push({ path: link });
  }
}
