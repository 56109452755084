import {Vue} from "vue-class-component";
import interact from 'interactjs';

export default class HandlersMixin extends Vue {
    protected handles: any = [];

    protected setHandlers(holder: any): void {
        holder.classList.add('resize-me');

        if (!holder.querySelectorAll('.resize-handler').length) {
            this.createHandles(holder);
        }
    }

    protected removeHandlers(holder: any): void {
        holder.classList.remove('resize-me');

        if (holder.querySelectorAll('.resize-handler').length) {
            holder.querySelectorAll('.resize-handler').forEach((el: any) => el.remove())
        }
    }

    protected createHandles(holder: any): void {
        const handles = [];
        for (let i = 0; i < 8; i++) {
            const handle: any = document.createElement('span');

            handle.setAttribute('data-width', 8);
            handle.setAttribute('data-height', 8);
            handle.classList.add("resize-handler")

            handles.push(handle);
            holder.appendChild(handle);
        }

        handles[0].classList.add('resize-top-left');
        handles[1].classList.add('resize-top');
        handles[2].classList.add('resize-top-right');
        handles[3].classList.add('resize-left');
        handles[4].classList.add('resize-right');
        handles[5].classList.add('resize-bottom-left');
        handles[6].classList.add('resize-bottom');
        handles[7].classList.add('resize-bottom-right');
    }
}
